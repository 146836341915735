(function (factory) {
	typeof define === 'function' && define.amd ? define('gMap', factory) :
	factory();
}((function () { 'use strict';

	function TMRGoogleMap(config) {
	  this.config = config;
	  this.init();
	}
	TMRGoogleMap.prototype.init = function () {
	  var objectRef = this;
	  this.interval = setInterval(function () {
	    var objectPointer = objectRef;
	    objectPointer.render();
	  }, 300);
	};
	TMRGoogleMap.prototype.render = function () {
	  var target = document.getElementById(this.config.id);
	  if (target) {
	    clearInterval(this.interval);
	    var myLatlng = new google.maps.LatLng(this.config.lon, this.config.lat);
	    var myOptions = {
	      zoom: this.config.zoom,
	      center: myLatlng,
	      mapTypeId: google.maps.MapTypeId.ROADMAP,
	      styles: this.config.styles
	    };
	    var map = new google.maps.Map(target, myOptions);
	    if (this.config.markers !== undefined) {
	      var gMapMarkers = [];
	      var nMarkers = this.config.markers.length;
	      for (var iMarker = 0; iMarker < nMarkers; iMarker++) {
	        var marker = this.config.markers[iMarker];
	        var gMapMarker = this.createMarker(map, marker);
	        gMapMarkers.push(gMapMarker);
	      }
	    }
	  }
	};
	TMRGoogleMap.prototype.createMarker = function (map, marker) {
	  // marker infowindow
	  var contentBuffer = Array();
	  contentBuffer.push('<div class="poi-info-window gm-style">');
	  contentBuffer.push('<div class="title full-width">');
	  contentBuffer.push(marker.name);
	  contentBuffer.push('</div>');
	  contentBuffer.push('<div class="address">');
	  for (var i = 0; i < marker.address.length; i++) {
	    contentBuffer.push(marker.address[i]);
	    contentBuffer.push('<br/>');
	  }
	  contentBuffer.push('</div>');
	  contentBuffer.push('</div>');
	  if (marker.directions == 1 && marker.address.length > 1) {
	    contentBuffer.push('<div class="tmrGMapDirections">');
	    contentBuffer.push(this.createDirectionsMarkup(marker));
	    contentBuffer.push('</div>');
	  }
	  //
	  var contentString = contentBuffer.join('');
	  var infowindow = new google.maps.InfoWindow({
	    content: contentString
	  });
	  //
	  var markerLatlng = new google.maps.LatLng(marker.lon, marker.lat);
	  var markerOptions = {
	    position: markerLatlng,
	    map: map,
	    title: marker.name
	  };
	  if (marker.icon !== undefined) {
	    markerOptions.icon = marker.icon;
	  }
	  var gMapMarker = new google.maps.Marker(markerOptions);
	  google.maps.event.addListener(gMapMarker, 'click', function () {
	    infowindow.open(map, gMapMarker);
	  });
	  if (marker.openOnStart) {
	    infowindow.open(map, gMapMarker);
	  }
	};
	TMRGoogleMap.prototype.createDirectionsMarkup = function (marker) {
	  var i18n = this.config.i18n;
	  var address = Array();
	  for (var i = 0; i < marker.address.length; i++) {
	    address.push(marker.address[i]);
	    if (i != marker.address.length - 1) {
	      address.push(', ');
	    }
	  }
	  var addressEncoded = encodeURIComponent(address.join(''));
	  var contentBuffer = Array();
	  contentBuffer.push('<a class="" target="_blank" href="');
	  contentBuffer.push(marker.url);
	  //contentBuffer.push('http://maps.google.com/maps?daddr=');
	  //contentBuffer.push(addressEncoded)
	  contentBuffer.push('">');
	  contentBuffer.push(i18n.route);
	  contentBuffer.push('</a>');
	  contentBuffer.push(' ');
	  return contentBuffer.join('');
	};
	function initMap(config) {
	  new TMRGoogleMap(config);
	}
	const config = {
	  "id": "tmrMap",
	  "lon": 47.42334017,
	  "lat": 7.76957095,
	  "zoom": 16,
	  "markers": [{
	    "lon": 47.42236384,
	    "lat": 7.77005643,
	    "address": ["Kirchgasse 17", "4434 Hölstein", "Schweiz"],
	    "name": "Parkplätze",
	    "url": "https://www.google.ch/maps/place/Kirchgasse+17,+4434+H%C3%B6lstein/@47.4220523,7.7694856,17z",
	    "directions": 1,
	    "icon": "/assets/img/map/parking_map.svg",
	    "openOnStart": false
	  }, {
	    "lon": 47.4239487,
	    "lat": 7.7694824,
	    "address": ["Ribigasse 3", "4434 Hölstein", "Schweiz"],
	    "name": "TMR Triangle Micro Research AG",
	    "url": "https://www.google.ch/maps/place/TMR+Triangle+Micro+Research+AG/@47.4239443,7.7694692,15z/data=!4m5!3m4!1s0x0:0xef5c25a5a424169d!8m2!3d47.4239443!4d7.7694692",
	    "directions": 1,
	    "icon": "/assets/img/map/tmrlogo_map.png",
	    "openOnStart": true
	  }],
	  "i18n": {
	    "route": "In Google Maps öffnen"
	  },
	  "styles": [{
	    "featureType": "poi.business",
	    "stylers": [{
	      "visibility": "off"
	    }]
	  }, {
	    "featureType": "poi.park",
	    "elementType": "labels.text",
	    "stylers": [{
	      "visibility": "off"
	    }]
	  }]
	};
	initMap(config);

})));
